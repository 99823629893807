
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {
    IconControlUp: () => import('./icons/IconControlUp.vue'),
  },
})
export default class MeetingSplit extends Vue {
  @Prop({
    required: true,
    type: Boolean,
  }) readonly isExpand!: boolean

  private changeSplitMode (): void {
    window.goal('Calendar', {
      Calendar: !this.isExpand
        ? 'Свернуть шторку встречи'
        : 'Развернуть шторку встречи',
    })
    this.$emit('changeSplitMode', !this.isExpand)
  }
}
